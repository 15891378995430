/* *,
*::before,
*::after {
  box-sizing: border-box;
  background: rgba(244, 246, 248, 1);
} */
body {
  margin: 0;
  padding: 0;
}

.react-custon-date-picker {
  width: 100%;
}

@font-face {
  font-family: "Neue Frutiger One";
  font-style: normal;
  font-weight: normal;
  src: local("Frutiger"),
    url("../src/assets/fonts/NeueFrutigerOne-Regular.woff") format("woff"),
    url("../src/assets/fonts/NeueFrutigerOne-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Neue Frutiger One Bold";
  font-style: normal;
  font-weight: bold;
  src: local("Frutiger Bold"),
    url("../src/assets/fonts/NeueFrutigerOne-Bold.woff") format("woff"),
    url("../src/assets/fonts/NeueFrutigerOne-Bold.woff2") format("woff2");
}
